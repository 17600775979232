import Vue from 'vue'
import Router from 'vue-router'
import cookies from '@/utils/cookies'
import { Toast } from "vant";
// const originalPush = Router.prototype.push
// Router.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }
Vue.use(Router)

export const constantRoutes = [{
    path: '/',
    name: 'home',
    meta: { auth: true, name: '首页', keepAlive: false },
    component: () =>
        import('@/views/home/index'),
},
// 星级管理
{
    path: '/startManage',
    name: 'startManage',
    meta: { auth: true, name: '星级管理' },
    component: () =>
        import('@/views/startManage/index'),
},
{
    path: '/startManage/item',
    name: 'startManage-item',
    meta: { auth: true, name: '星级详情' },
    component: () =>
        import('@/views/startManage/item'),
},
{
    path: '/startManage/applyStar',
    name: 'startManage-applyStar',
    meta: { auth: true, name: '星级申请' },
    component: () =>
        import('@/views/startManage/applyStar'),
},

// 培训管理
{
    path: '/trainManage',
    name: 'trainManage',
    meta: { auth: true, name: '培训管理' },
    component: () =>
        import('@/views/trainManage/index'),
},
// 司机管理
{
    path: '/drivers',
    name: 'drivers',
    meta: { auth: true, name: '司机管理' },
    component: () =>
        import('@/views/drivers/index'),
},
// 司机添加
{
    path: '/drivers/add',
    name: 'drivers/add',
    meta: { auth: true, name: '添加司机' },
    component: () =>
        import('@/views/drivers/add'),
},
// 司机添加
{
    path: '/drivers/edit',
    name: 'drivers/edit',
    meta: { auth: true, name: '编辑司机' },
    component: () =>
        import('@/views/drivers/edit'),
},
{
    path: '/trainManage/item',
    name: 'trainManage-item',
    meta: { auth: true, name: '培训详情' },
    component: () =>
        import('@/views/trainManage/item'),
},
// ---
{
    path: '/agreement',
    name: 'agreement',
    meta: { auth: true, name: '用户协议' },
    component: () =>
        import('@/views/agreementPrivacy/agreement'),
},
{
    path: '/privacy',
    name: 'privacy',
    meta: { auth: true, name: '隐私条款' },
    component: () =>
        import('@/views/agreementPrivacy/privacy'),
},
{
    path: '/profile',
    name: 'profile',
    meta: { auth: true, name: '个人中心', keepAlive: false },
    component: () =>
        import('@/views/profile/index'),
},
{
    path: '/profile/edit',
    name: 'profile-edit',
    meta: { auth: true, name: '编辑信息', keepAlive: false },
    component: () =>
        import('@/views/profile/edit'),
},
{
    path: '/profile/update-password',
    name: 'profile-update-password',
    meta: { auth: true, name: '修改密码', keepAlive: false },
    component: () =>
        import('@/views/profile/update-password'),
},
{
    path: '/profile/sub-users',
    name: 'sub-users',
    meta: { auth: false, name: '子账号列表', keepAlive: false },
    component: () =>
        import('@/views/profile/sub-users'),
},
{
    path: '/profile/add-subuser',
    name: 'add-subuser',
    meta: { auth: false, name: '添加子账号', keepAlive: false },
    component: () =>
        import('@/views/profile/add-subuser'),
},
{
    path: '/profile/edit-subuser',
    name: 'edit-subuser',
    meta: { auth: false, name: '修改子账号', keepAlive: false },
    component: () =>
        import('@/views/profile/edit-subuser'),
},
{
    path: '/profile/update-subuser-password',
    name: 'edit-subuser',
    meta: { auth: false, name: '修改子账号密码', keepAlive: false },
    component: () =>
        import('@/views/profile/update-subuser-password'),
},

{
    path: '/login',
    name: 'login',
    meta: { auth: true, name: '登录', keepAlive: false },
    component: () =>
        import('@/views/login/index'),
},

{
    path: '/register',
    name: 'register',
    meta: { auth: true, name: '注册', keepAlive: false },
    component: () =>
        import('@/views/register/index'),
},
{
    path: '/guider',
    name: 'guider',
    meta: { auth: true, name: '导游管理', keepAlive: false },
    component: () =>
        import('@/views/guider/index'),
},
{
    path: '/guider/add',
    name: 'guider-add',
    meta: { auth: true, name: '添加导游', keepAlive: false },
    component: () =>
        import('@/views/guider/add'),
},
{
    path: '/guider/item',
    name: 'guider-item',
    meta: { auth: true, name: '导游详情', keepAlive: false },
    component: () =>
        import('@/views/guider/item'),
},
{
    path: '/guider/feedbacks',
    name: 'guider-feedbacks',
    meta: { auth: true, name: '导游评价', keepAlive: false },
    component: () =>
        import('@/views/guider/feedbacks'),
},
{
    path: '/guider/temp_agency',
    name: 'guider-temp_agency',
    meta: { auth: true, name: '导游借调旅行社', keepAlive: false },
    component: () =>
        import('@/views/guider/temp_agency'),
},
{
    path: '/guider/agency',
    name: 'guider-agency',
    meta: { auth: true, name: '旅行社信息', keepAlive: false },
    component: () =>
        import('@/views/guider/agency'),
},
{
    path: '/trip',
    name: 'trip',
    meta: { auth: true, name: '行程管理', keepAlive: false },
    component: () =>
        import('@/views/trip/index'),
},
{
    path: '/trip/item',
    name: 'trip-item',
    meta: { auth: true, name: '出团详情', keepAlive: false },
    component: () =>
        import('@/views/trip/item'),
},
{
    path: '/trip/edit',
    name: 'trip-edit',
    meta: { auth: true, name: '行程编辑', keepAlive: false },
    component: () =>
        import('@/views/trip/edit'),
},
{
    path: '/trip/add',
    name: 'trip-add',
    meta: { auth: true, name: '行程添加', keepAlive: false },
    component: () =>
        import('@/views/trip/add'),
},
{
    path: '/tuan',
    name: 'tuan',
    meta: { auth: true, name: '出团管理', keepAlive: false },
    component: () =>
        import('@/views/tuan/index'),
},
{
    path: '/tuan/item',
    name: 'tuan-item',
    meta: { auth: true, name: '出团详情', keepAlive: false },
    component: () =>
        import('@/views/tuan/item'),
},
{
    path: '/tuan/add',
    name: 'tuan-add',
    meta: { auth: true, name: '出团添加', keepAlive: false },
    component: () =>
        import('@/views/tuan/add'),
},
{
    path: '/tuan/edit',
    name: 'tuan-edit',
    meta: { auth: true, name: '出团编辑', keepAlive: false },
    component: () =>
        import('@/views/tuan/edit'),
},
{
    path: '/message',
    name: 'message',
    meta: { auth: true, name: '消息通知', keepAlive: false },
    component: () =>
        import('@/views/message/index'),
},
{
    path: '/message/item',
    name: 'message-item',
    meta: { auth: true, name: '消息详情', keepAlive: false },
    component: () =>
        import('@/views/message/item'),
},
{
    path: '/message/jiangbuinfo',
    name: 'jiangbuinfo',
    meta: { auth: true, name: '奖补详情', keepAlive: false },
    component: () =>
        import('@/views/message/jiangbuinfo'),
},
{
    path: '/message/jiangbuList',
    name: 'jiangbu-list',
    meta: { auth: true, name: '待申请奖补', keepAlive: false },
    component: () =>
        import('@/views/message/jiangbuList'),
},

{
    path: '/message/issue',
    name: 'message-issue',
    meta: { auth: true, name: '消息发布', keepAlive: false },
    component: () =>
        import('@/views/message/issue'),
},
{
    path: '/message/emergencies-item',
    name: 'emergencies-item',
    meta: { auth: true, name: '消息详情', keepAlive: false },
    component: () =>
        import('@/views/message/emergencies-item'),
},
{
    path: '/feedbacks',
    name: 'feedbacks',
    meta: { auth: true, name: '反馈', keepAlive: false },
    component: () =>
        import('@/views/feedbacks/index'),
},
{
    path: '/feedbacks/item',
    name: 'feedbacks-item',
    meta: { auth: true, name: '反馈详情', keepAlive: false },
    component: () =>
        import('@/views/feedbacks/item'),
},
{
    path: '/report',
    name: 'report',
    meta: { auth: true, name: '事件上报', keepAlive: false },
    component: () =>
        import('@/views/report/index'),
},
{
    path: '/report/item',
    name: 'report-item',
    meta: { auth: true, name: '事件详情', keepAlive: false },
    component: () =>
        import('@/views/report/item'),
},
{
    path: '/dataCenter',
    name: 'dataCenter',
    meta: { auth: true, name: '数据统计', keepAlive: false },
    component: () =>
        import('@/views/dataCenter/index'),
},
{
    path: '/archive-info/item',
    name: 'archive-info-item',
    meta: { auth: true, name: '备案详情' },
    component: () =>
        import('@/views/archive-info/item'),
},
{
    path: '/dailyWeekly/item',
    name: 'dailyWeekly-item',
    meta: { auth: true, name: '报告详情' },
    component: () =>
        import('@/views/dailyWeekly/item'),
},
{
    path: '/dailyWeekly/reportLists',
    name: 'dailyWeekly-reportLists',
    meta: { auth: true, name: '报告详情' },
    component: () =>
        import('@/views/dailyWeekly/report.lists'),
},
{
    path: '/dailyWeekly',
    name: 'dailyWeekly',
    meta: { auth: true, name: '日报/周报' },
    component: () =>
        import('@/views/dailyWeekly/index'),
},
{
    path: '/poster',
    name: 'poster',
    meta: { auth: true, name: '海报' },
    component: () =>
        import('@/views/poster/index'),
},
{
    path: '/scan',
    name: 'scan',
    meta: { auth: true, name: '扫码', keepAlive: false },
    component: () =>
        import('@/views/scan/index'),
},
{
    path: '/yiqing',
    name: 'yiqing',
    meta: { auth: true, name: '疫情', keepAlive: true },
    redirect: { name: "yiqingMain" },
    component: () =>
        import('@/views/yiqing/index'),
    children: [{
        path: 'main',
        name: 'yiqingMain',
        meta: { auth: true, name: '疫情信息' },
        component: () =>
            import('@/views/yiqing/main')
    },
    {
        path: 'area',
        name: 'yiqingArea',
        meta: { auth: true, name: '疫情信息' },
        component: () =>
            import('@/views/yiqing/area.detail')
    },
    {
        path: 'riskarea',
        name: 'yiqingRiskarea',
        meta: { auth: true, name: '风险地区' },
        component: () =>
            import('@/views/yiqing/risk.area')
    },

    ]
},


]

const createRouter = () => new Router({
    mode: 'history', // require service support
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})


const router = createRouter()

// router.beforeEach((to, from, next) => {
router.beforeEach((to, from, next) => {
    if (from.path == '/guider/item' && to.path == "/guider") {
        to.query.type = from.query.type
    }
    if (from.path == '/guider/add' && to.path == "/guider") {
        to.query.type = from.query.type
    }
    if (from.path == '/tuan/add' && to.path == "/tuan") {
        to.query.type = from.query.type
    }
    if (from.path == '/tuan/item' && to.path == "/tuan") {
        to.query.type = from.query.type
    }
    if (from.path == '/message/item' && to.path == "/message") {
        to.query.type = from.query.type
    }
    if (from.path == '/message/issue' && to.path == "/message") {
        to.query.type = from.query.type
    }
    if (from.path == '/dailyWeekly/item' && to.path == "/message") {
        to.query.type = from.query.type
    }
    if (from.path == '/dailyWeekly' && to.path == "/message") {
        to.query.type = from.query.type
    }
    if (from.path == '/feedbacks/item' && to.path == "/feedbacks") {
        to.query.type = from.query.type
    }
    if (from.path == '/archive-info/item' && to.path == "/feedbacks") {
        to.query.type = from.query.type
    }
    if (from.path == '/report/item' && to.path == "/feedbacks") {
        to.query.type = from.query.type
    }
    if (from.path == '/report' && to.path == "/feedbacks") {
        to.query.type = from.query.type
    }
    const publicPages = ['/login', '/register', '/agreement', '/privacy']
    if (publicPages.includes(to.path)) {
        cookies.remove('token')
    }
    const authRequired = !publicPages.includes(to.path)

    const loggedIn = cookies.get('token')

    if (to.meta.auth) {
        if (authRequired && !loggedIn) {
            Toast('您还未登录，请登录后操作!')
            return next('/login')
        }
    }
    next()
})

export default router