import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import Vant from 'vant';
import apis from '@/services/apis'

import 'vant/lib/index.css'
import "@/svgIcon/index.js"
Vue.use(Vuex)
    // 导入组件库
import VueCanvasPoster from 'vue-canvas-poster'
// 注册组件库
Vue.use(VueCanvasPoster)

Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$apis = apis;
new Vue({
    router,
    render: h => h(App),
}).$mount('#app')