import axios from 'axios' // 引入axios
// import qs from 'qs'
import { Toast } from 'vant';
import cookies from "../utils/cookies";
import { baseURL } from './config'
// 创建axios实例
const service = axios.create({ // 对axios进行基础匹配
    // baseURL: 'https://www.wenlvanquan.com:4002/agency/h5/v1', //测试地址
    // baseURL: 'https://www.wenlvanquan.com:9292/agency/h5/v1', //正式地址
    baseURL: baseURL,
    timeout: 500000,
    withCredentials: false,
    validateStatus: function(status) {

        if (status == 401) {
            window.location.href = "/login"
            return 401
        }

        return status >= 200 && status < 300
    }
})

// 设置请求拦截器
service.interceptors.request.use(config => {
        const token = cookies.get('token') ? cookies.get('token') : ''
        config.headers = {
            'token': token,
            // 'Content-Type': 'application/x-www-form-urlencoded',
            'Content-Type': 'application/json,charset=utf-8',
            'crossDomain': false
        }
        return config
    }, error => {
        Toast.fail('请求失败!')
        return Promise.reject(error)
    })
    // 设置响应拦截器
service.interceptors.response.use(data => {
    if (data.data === null) {
        return null;
    }
    if (data.data === '') {
        return null;
    }
    if (data.data) {
        if (data.status == 401) {

            Toast(data.data.error)
            window.location.href = "/login"
            return data.data
        }
        if (data.data.status != 200) {
            if (data.error) {
                Toast(data.error)
            } else {
                Toast(data.data.message)
            }


        }
        return data.data
    } else {}

}, error => {
    Toast(error + '')
    return Promise.reject(error)
})

export default service