import request from '@/services/request'
const apis = {
    // ---奖补相关---
    // 我的奖补列表
    get_jiangbu_list(query) {
        return request({
            url: "/jiang_bus/my_jiang_bu_user_relations",
            method: "get",
            params: query
        })
    },
    // 可以申请的奖补列表
    get_applyjiangbu_list(query) {
        return request({
            url: "/jiang_bus/jiang_bu_user_relations",
            method: "get",
            params: query
        })
    },

    // 奖补名单
    get_jiang_bu_list(query) {
        return request({
            url: "/jiang_bus/jiang_bu_user_relations",
            method: "get",
            params: query
        })
    },
    // 奖补详情
    get_jiangbu_mes(id) {
        return request({
            url: `/jiang_bus/${id}`,
            method: "get"
        })
    },
    // 申请奖补
    apply_jiangbu(id) {
        return request({
            url: `/jiang_bus/${id}/apply`,
            method: "put"
        })
    },
    // ---培训相关---
    // 培训签到
    traing_sign(id, data) {
        return request({
            url: `/trainings/${id}/sign`,
            method: "post",
            data: data
        })
    },
    // 获取培训列表
    get_traings_list(query) {
        return request({
            url: "/trainings/training_user_relations",
            method: "get",
            params: query
        })
    },
    // 获取培训详情
    get_traings_mes(id) {
        return request({
            url: `/trainings/${id}`,
            method: "get"
        })
    },
    // ---星级管理相关---
    // 获取星级申请列表
    get_star_list(query) {
        return request({
            url: "/company_star_lists",
            method: "get",
            params: query
        })
    },
    // 星级详情
    get_star_mes(id) {
        return request({
            url: `/company_star_lists/${id}`,
            method: "get"
        })
    },
    // 新增星级申请
    add_star_mes(data) {
        return request({
            url: `/company_star_lists`,
            method: "post",
            data: data
        })
    },
    // 修改星级申请
    edit_star_mes(id, data) {
        return request({
            url: `/company_star_lists/${id}`,
            method: "put",
            data: data
        })
    },

    // ---

    //旅行社列表
    get_travel_companies(query) {
        return request({
            url: "/travel_companies",
            method: "get",
            params: query
        })
    },
    //修改旅行社
    put_travel_companies() {
        return request({
            url: "/travel_companies",
            method: "put"
        })
    },
    //获取单个旅行社
    get_travel_companie(id) {
        return request({
            url: "/travel_companies/" + id,
            method: "get"
        })
    },
    //----
    //登录
    sing_in(user) {
        return request({
            url: '/companies/login',
            method: 'post',
            data: user
        })
    },
    //发送短信验证码
    sms_codes(mobile) {
        return request({
            url: '/companies/sms_codes',
            method: 'post',
            data: {
                mobile: mobile
            }
        })
    },
    //获取可选旅行社
    select_companies() {
        return request({
            url: '/companies/select_companies',
            method: 'get'
        })
    },
    //register
    register(data) {
        return request({
            url: '/companies/regist',
            method: 'post',
            data: data
        })
    },
    //创建旅行社
    post_agency(data) {
        return request({
            url: '/companies',
            method: 'post',
            data: data
        })
    },
    //导游列表
    get_guides(data) {
        return request({
            url: "/guides",
            method: "get",
            params: data
        })
    },
    //获取单个导游
    get_guide(id) {
        return request({
            url: "/guides/" + id,
            method: "get"
        })
    },
    //更新导游信息
    update_guides(id, data) {
        return request({
            url: `/companies/${id}/update_guides`,
            method: 'put',
            data: data
        })
    },
    //删除导游信息
    delete_guides(id) {
        return request({
            url: `/guides/${id}`,
            method: 'delete'
        })
    },
    //移除添加导游
    remove_guide_from_company(id) {
        return request({
            url: `/guides/${id}/remove_guide_from_company`,
            method: 'put'
        })
    },
    //删除出团记录
    delete_travel_list(id) {
        return request({
            url: `/travel_lists/${id}`,
            method: 'delete'
        })
    },
    //删除旅游线路
    delete_travel_plan(id) {
        return request({
            url: `/travel_plans/${id}`,
            method: 'delete'
        })
    },
    //获取当前旅行社信息
    get_current_company() {
        return request({
            url: `/companies/current_company`,
            method: 'get'
        })
    },
    //获取当前旅行社信息
    update_current_company(data) {
        return request({
            url: `/companies/update_current_company`,
            method: 'put',
            data: data
        })
    },
    //获取旅行社信息
    get_company(id) {
        return request({
            url: `/companies/${id}`,
            method: 'get'
        })
    },
    //修改旅行社信息
    put_company(id, data) {
        return request({
            url: `/companies/${id}`,
            method: 'put',
            data: data
        })
    },
    //发布
    post_notifications(data) {
        return request({
            url: "/notifications",
            method: "post",
            data: data
        })
    },
    //获取发布信息
    get_notifications(query) {
        return request({
            url: "/notifications",
            method: "get",
            params: query
        })
    },
    //阅读发布信息
    read_notification(relation_id) {
        return request({
            url: "/notifications/read_notification",
            method: "put",
            data: {
                relation_id: relation_id
            }
        })
    },
    //获取单个发布信息
    get_notification(relation_id) {
        return request({
            url: "/notifications/notification",
            method: "get",
            params: {
                relation_id: relation_id
            }
        })
    },
    //获取团行列表
    get_travel_lists(query) {
        return request({
            url: `/travel_lists`,
            method: 'get',
            params: query
        })
    },
    get_travel_lists_from_ids(ids, page = 1) {
        return request({
            url: '/travel_lists/lists_from_ids',
            method: 'get',
            params: { ids: ids, page: page }
        })
    },
    //获取单个团行
    get_travel_list(id) {
        return request({
            url: `/travel_lists/${id}`,
            method: 'get'
        })
    },
    //创建团行
    post_travel_list(data) {
        return request({
            url: `/travel_lists/`,
            method: 'post',
            data: data
        })
    },
    //获取可选线路
    get_select_travel_plans() {
        return request({
            url: `/travel_plans/select_plans`,
            method: 'get'
        })
    },
    //获取可选导游
    get_select_guides() {
        return request({
            url: "/guides/select_guides",
            method: "get"
        })
    },
    //修改单个团行
    put_travel_list(id, data) {
        return request({
            url: `/travel_lists/${id}`,
            method: 'put',
            data: data
        })
    },
    //线路列表
    get_travel_plans(query) {
        return request({
            url: `/travel_plans`,
            method: 'get',
            params: query
        })
    },
    //获取单个线路
    get_travel_plan(id) {
        return request({
            url: `/travel_plans/${id}`,
            method: 'get'
        })
    },
    //创建线路
    post_travel_plans(data) {
        return request({
            url: `/travel_plans`,
            method: 'post',
            data: data
        })
    },
    //修改线路
    put_travel_plans(data, id) {
        return request({
            url: `/travel_plans/${id}`,
            method: 'put',
            data: data
        })
    },
    //获取未添加导游列表
    get_no_company_guides(query) {
        return request({
            url: `/guides/no_company_guides`,
            method: 'get',
            params: query
        })
    },
    //添加导游
    add_guide_to_company(guide_id) {
        return request({
            url: `/guides/add_guide_to_company`,
            method: 'post',
            data: {
                guide_id: guide_id
            }
        })
    },
    //对评价进行回复
    reply_feedbacks(id, data) {
        return request({
            url: `/feedbacks/${id}/replies`,
            method: 'post',
            data: data
        })
    },
    //获取评价列表
    get_feedbacks(query) {
        return request({
            url: `/feedbacks`,
            method: 'get',
            params: query
        })
    },
    //获取单个评价
    get_feedback(id) {
        return request({
            url: `/feedbacks/${id}`,
            method: 'get'
        })
    },
    //评价
    put_feedback(id, data) {
        return request({
            url: `/feedbacks/${id}`,
            method: 'put',
            data: data
        })
    },
    //获取线路节点
    get_plan_nodes(travel_plan_id) {
        return request({
            url: `/travel_plan_nodes/get_plan_nodes`,
            method: 'get',
            params: {
                travel_plan_id: travel_plan_id
            }
        })
    },
    //选择出团GET 
    select_travel_lists() {
        return request({
            url: `/travel_lists/select_travel_lists`,
            method: 'get'
        })
    },
    //新建上报POST /agency/h5/v1/emergencies
    post_emergency(data) {
        return request({
            url: `/emergencies`,
            method: 'post',
            data: data
        })
    },
    //获取单个突发事件
    get_emergency(id) {
        return request({
            url: `/emergencies/${id}`,
            method: 'get'
        })
    },
    emergency_reports_from_ids(ids, page = 1) {
        return request({
            url: `/emergencies/reports_from_ids`,
            method: 'get',
            params: { ids: ids, page: page }
        })
    },
    //突发事件列表
    get_emergencies(query) {
        return request({
            url: `/emergencies`,
            method: 'get',
            params: query
        })
    },
    //PUT /agency/h5/v1/travel_lists/{id}/cancel_list
    cancel_list(id, data) {
        return request({
            url: `/travel_lists/${id}/cancel_list`,
            method: 'put',
            data: data
        })
    },
    //导游图表数据statistics/guides
    statistics_guides() {
        return request({
            url: "/statistics/guides",
            method: "get"
        })
    },
    //出团数据statistics/travel_lists
    statistics_travel_lists() {
        return request({
            url: "/statistics/travel_lists",
            method: "get"
        })
    },
    //行程图表数据
    statistics_travel_plans() {
        return request({
            url: "/statistics/travel_plans",
            method: "get"
        })
    },
    //获取可注册地区数据
    get_region_data() {
        return request({
            url: "/companies/region_data",
            method: "get"
        })
    },
    //emergency_replyPOST /agency/h5/v1/emergencies/{id}/replies
    emergency_reply(id, data) {
        return request({
            url: `/emergencies/${id}/replies`,
            method: "post",
            data: data
        })
    },
    //行程备案
    get_archive_infos() {
        return request({
            url: "/archive_infos",
            method: "get",
        })
    },
    //行程备案详情
    get_archive_info(id) {
        return request({
            url: `/archive_infos/${id}`,
            method: "get",
        })
    },
    //GET /agency/h5/v1/archive_infos/archive_info
    get_vistor_archive_info(travel_list_id, tourist_id) {
        return request({
            url: `/archive_infos/archive_info`,
            method: "get",
            params: {
                travel_list_id: travel_list_id,
                tourist_id: tourist_id
            }
        })
    },
    //GET /agency/h5/v1/guides/search_tmp_guides 搜索借调的导游
    search_tmp_guides(query) {
        return request({
            url: `/guides/search_tmp_guides`,
            method: "get",
            params: query
        })
    },
    //POST /agency/h5/v1/guides/add_guide_to_tmp_relation 临时添加导游
    add_guide_to_tmp_relation(guide_id) {
        return request({
            url: `/guides/add_guide_to_tmp_relation`,
            method: "post",
            data: {
                guide_id: guide_id
            }
        })
    },
    //GET /agency/h5/v1/guides/tmp_guides 临时添加导游列表
    get_tmp_guides(query) {
        return request({
            url: `/guides/tmp_guides`,
            method: "get",
            params: query
        })
    },
    get_tmp_guide(guide_id) {
        return request({
            url: `/guides/tmp_guide`,
            method: "get",
            params: {
                guide_id: guide_id
            }
        })
    },
    //DELETE /agency/h5/v1/guides/{id}/remove_guide_from_tmp_relation 解除借调
    remove_guide_from_tmp_relation(id) {
        return request({
            url: `/guides/${id}/remove_guide_from_tmp_relation`,
            method: "delete"

        })
    },
    //GET /agency/h5/v1/reports 日报周报
    get_reports(query) {
        return request({
            url: `reports`,
            method: 'get',
            params: query
        })
    },
    //发布日报周报
    post_reports(data) {
        return request({
            url: `reports`,
            method: 'post',
            data: data
        })
    },
    //获取到日报周报详情
    get_report(id) {
        return request({
            url: `reports/${id}`,
            method: 'get'
        })
    },
    //companies/count_info
    count_info() {
        return request({
            url: `/companies/count_info`,
            method: 'get'
        })
    },
    get_sub_users(query) {
        return request({
            url: "/companies/sub_users",
            method: "get",
            params: query
        })
    },
    //添加子账号
    add_user(data) {
        return request({
            url: "/companies/add_sub_user",
            method: "post",
            data: data
        })
    },
    //DELETE /boss/h5/v1/bosses/delete_sub_user 删除子账号
    delete_sub_user(user_id) {
        return request({
            url: `/companies/delete_sub_user`,
            method: 'delete',
            data: {
                user_id: user_id
            }
        })
    },
    //PUT /boss/h5/v1/bosses/edit_sub_user 编辑子账号
    edit_sub_user(user_id, data) {
        return request({
            url: `/companies/edit_sub_user`,
            method: 'put',
            data: {
                user_id: user_id,
                user: data
            }
        })
    },
    get_company(id) {
        return request({
            url: `/companies/company`,
            method: 'get',
            params: { id: id }
        })

    },
    //导游获取借调旅行社
    get_tem_companies(guider_id, query) {
        return request({
            url: `guides/${guider_id}/tem_companies`,
            method: 'get',
            params: query
        })

    },
    yiqing_info() {
        return request({
            url: "/ncov/ncov_info ",
            method: 'get'
        })
    },
    // POST /agency/h5/v1/companies/ocr_license_img 图片识别营业执照
    ocr_license_img(license_img_url) {
        return request({
            url: "/companies/ocr_license_img ",
            method: 'post',
            data: {
                license_img_url: license_img_url
            }
        })
    },
    tourists_from_ids(ids, list_ids, page = 1) {
        return request({
            url: "/tourists/tourists_from_ids",
            method: "get",
            params: { ids: ids, list_ids: list_ids, page: page }
        })
    },
    //司机列表
    get_drivers(params) {
        return request({
            url: `/drivers`,
            method: 'get',
            params: params
        })
    },
    //司机详情
    get_driver(id) {
        return request({
            url: `/drivers/${id}`,
            method: 'get',
        })
    },
    //修改司机
    put_driver(id, data) {
        return request({
            url: `/drivers/${id}`,
            method: 'put',
            data: data
        })
    },
    //添加司机
    post_driver(data) {
        return request({
            url: `/drivers`,
            method: 'post',
            data: data
        })
    },
    //删除司机
    delete_driver(id) {
        return request({
            url: `/drivers/${id}`,
            method: 'delete'
        })
    },

}

export default apis