<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
@import "./assets/css/style.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(179deg, #6C94F6 0%, #436EF0 16%, #3A5BE9 100%);
  height: 100vh;
  width: 100vw;
  background-size: 100%;
  overflow-y: hidden;
  /* padding-top: 10vh; */
  position: fixed;
  top: 0;
  left: 0;
}

h3 {
  color: #444;
  margin-bottom: 10px;
}

.van-step__title p {
  margin-bottom: 10px !important;
}

.wrpper-title-large {
  color: #333;
  font-size: .3rem;
  font-weight: bold;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
}

.van-radio__label {
  color: #fff !important;
}

/* .van-uploader{
  height: 88px !important;
  width: 88px !important;
} */
</style>
